@import "./variables";

:root {
	--contentPadding: 2rem;

	@media (max-width: 500px) {
		--contentPadding: 1rem;
		font-size: .8rem;
	}
}

*, *::before, *::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: inherit;
}

html, body {
	scroll-behavior: smooth;
}

body {
	font-family: 'Open Sans', sans-serif;
	color: #494949;
}

main {
	margin: 2rem auto;
	padding: 2rem 1rem 4rem calc(#{$backgroundTileWidth} * 1.5 + 1.5rem); //bottom: breathing room; left: icon spacing+gap
	max-width: 1120px;
	background: url(assets/images/background.svg) 1rem top repeat-y;
	background-size: $backgroundTileWidth auto;
	position: relative;

	&.start {
		padding: 2rem 1rem;
		background: none;
	}

	@media (max-width: $bootstrapTabletBreakpoint) {
		padding: 2rem 1rem;
		background: none;
	}

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: $backgroundTileWidth;
		background: linear-gradient(transparent, white);

		@media (max-width: $bootstrapTabletBreakpoint) {
			content:none;
		}
	}
}

.page-heading {
	font-size: 3rem;
	line-height: 1.1;
	margin-bottom: .5em;
}

.button {
	padding: .75em 2.25em;
	font-size: .9rem;
	font-weight: bold;
	text-transform: uppercase;
	color: white;
	background: #ba0c2f;
	transition: background .3s;
	cursor: pointer;
	border: none;
	user-select: none;
	border-radius: 3px;
	text-decoration: none;
	display: inline-block;

	&:hover {
		background: #972534;
	}

	&[disabled] {
		cursor: not-allowed;
		color: #808080;
		background: #EEEEEE;
	}

	&.large {
		font-size: 1.25rem;
		border-radius: 6px;
	}
}

.center {
	text-align: center;
	padding-right: calc(#{$backgroundTileWidth} + 1.5rem); //offsets for tiled image background

	@media (max-width: $bootstrapTabletBreakpoint) {
		padding-right: 0;
	}
}

.chosen-answer {
	color: green;
}

.default-button {
	color: gray;
}