.start-page {
	h1 {
		img {
			max-width: 800px;
			width:100%;
			display: block;
			margin:0 auto;
		}
	}

	p {
		font-weight: 600;
		font-size: 1.5rem;
		margin: 2em 0;
	}
}