.question-page {
	h1 {
		padding: 0 var(--contentPadding); //equalizes left indent to match question text
	}

	.error {
		color: #ba0c2f;
		font-style: italic;
		font-size: 1.25rem;
		margin-bottom: 1em;
	}
}