.bsu-footer {

//---------------------------------------
//---------------------------------------
//OVERRIDES
//---------------------------------------
//---------------------------------------

	font-family: 'Open Sans', sans-serif;


//---------------------------------------
//---------------------------------------
//COPIED FROM BSU.EDU as of November 2022
//---------------------------------------
//---------------------------------------

	/*! CSS Used from: https://bsu.edu/Components/Design/ResponsiveV2/css/v-637973620180000000/bsu-bootstrap.css */
	a{background-color:transparent;}
	a:active,a:hover{outline:0;}
	strong{font-weight:bold;}
	img{border:0;max-width:100%;height:auto!important;}
	*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
	*:before,*:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
	a{color:#ba0c2f;text-decoration:none;font-weight:600;}
	a:hover,a:focus{color:#ba0c2f;text-decoration:underline;-webkit-transition:color .2s ease-in-out;-o-transition:color .2s ease-in-out;transition:color .2s ease-in-out;-webkit-transition:background-color .2s ease-in-out;-o-transition:background-color .2s ease-in-out;transition:background-color .2s ease-in-out;}
	a:focus{outline:thin dotted;outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
	img{vertical-align:middle;border-radius:3px;}
	ul{margin-top:0;margin-bottom:11px;}
	.list-inline{padding-left:0;list-style:none;margin-left:-5px;}
	.list-inline>li{display:inline-block;padding-left:5px;padding-right:5px;}
	.container{margin-right:auto;margin-left:auto;padding-left:15px;padding-right:15px;}
	.container:before,.container:after{content:" ";display:table;}
	.container:after{clear:both;}
	@media (min-width:768px){
		.container{width:750px;}
	}
	@media (min-width:992px){
		.container{width:970px;}
	}
	@media (min-width:1200px){
		.container{width:1170px;}
	}
	.row{margin-left:-15px;margin-right:-15px;}
	.row:before,.row:after{content:" ";display:table;}
	.row:after{clear:both;}
	@media screen and (max-width:480px){
		.row{margin-left:0;margin-right:0;}
	}
	.container{min-width:320px;}
	footer a[title="Ball State University"],#footer a[title="Ball State University"]{padding:3px;display:inline-block;}
	footer a[title="Ball State University"]:hover,footer a[title="Ball State University"]:focus,#footer a[title="Ball State University"]:hover,#footer a[title="Ball State University"]:focus{border:solid 1px #ba0c2f;outline:0;}
	footer a,#footer a{color:#555;font-weight:bold;}
	footer a:hover,footer a:focus,#footer a:hover,#footer a:focus{color:#ba0c2f;}
	footer .list-inline li,#footer .list-inline li{border-right:solid 1px #555;padding-right:7px;line-height:14px;}
	footer .list-inline li:last-child,#footer .list-inline li:last-child{border-right:none;}
	footer img,#footer img{margin-bottom:15px;line-height:20px;}
	@media (min-width:768px){
		footer img,#footer img{margin-bottom:0;display:inline-block;}
	}
	/*! CSS Used from: https://bsu.edu/Components/Design/ResponsiveV2/css/v-638016029940000000/bsu-bootstrap-templates.css */
	@media print{
		*,*:before,*:after{color:#000!important;box-shadow:none!important;text-shadow:none!important;}
		a,a:visited{text-decoration:none;border-bottom:1px solid #000;}
		a[href]:after{content:" (" attr(href) ")";font-size:6pt;text-transform:lowercase!important;border-bottom:5px solid #fff!important;}
		img{page-break-inside:avoid;}
		img{max-width:50%!important;}
		a[href^="/"]:after{content:" (https://www.bsu.edu" attr(href) ")"!important;font-size:6pt!important;text-transform:lowercase!important;border-bottom:5px solid #fff!important;}
		#footer .container{display:block!important;width:auto!important;max-width:100%!important;}
	}
	#footer>div.container{width:100%!important;}
	@media screen and (max-width:479px){
		#footer>div.container{padding:0;}
	}
	#footer a{color:#fff;font-weight:600;}
	#footer a:hover,#footer a:focus{color:#fff;text-decoration:underline;}
	#footer .footerbottom{background:#000;color:#fff;padding:15px;}
	#footer .footerlogo{padding:20px 0 10px 0;background-position:center center;background-size:cover;}
	#footer .footerlogo a:hover{border:none;text-decoration:none;}
	#footer .footerlogo img{margin-bottom:10px;max-height:185px;}
	footer, #footer {
		text-align: center;
		font-size: 12px;
		color: #555;
		background: #fff;
		padding: 0 !important;
	}
}