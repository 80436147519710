@import "../../variables";

@media (min-width: calc($bootstrapMobileBreakpoint + 1px)) {
  @keyframes flip {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(180deg);
    }
  }
  @keyframes flip-text {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(-180deg);
    }
  }
  @-webkit-keyframes flip {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(180deg);
    }
  }
  @-webkit-keyframes flip-text {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(-180deg);
    }
  }
}

@keyframes color-change {
  0% {
    opacity: 0.5;
  }
  100% {
    transform: scale(80);
    opacity: 1;
  }
}

@-webkit-keyframes color-change {
  0% {
    opacity: 0.5;
  }
  100% {
    transform: scale3d(80,80,80);
    opacity: 1;
  }
}

:root {
  --x-coord: 0;
  --y-coord: 0;
  --content: none;
}

.question-box {
  z-index: 3;
  margin-bottom: 5rem;
  header {
    display: flex;
    align-items: center;
    padding: 0 var(--contentPadding); //equalizes left indent to match question text
    margin-bottom: 1rem;

    label {
      font-size: 1.25rem;
      font-weight: 300;
      margin-right: 1.5em;
    }

    ul {
      display: flex;
      list-style: none;
    }

    li {
      --radius: 15px;
      height: var(--radius);
      width: var(--radius);
      border-radius: 50%;
      background: #D9D9D9;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }

      &.current {
        background: #ba0c2f;
      }

      @media (max-width: $bootstrapMobileBreakpoint) {
        --radius: 10px;
      }
    }
  }

  .question-wrapper {
    background: #F4F4F4;
    padding: 2rem var(--contentPadding);

    h2 {
      margin-bottom: 1.5rem;
      font-size: 2rem;
      line-height: 1.15;
    }

    .choices {
      list-style: none;
      display: grid;
      grid-template-columns: repeat(auto-fill, 22%);
      gap: 15px;
      text-align: center;

      @media (max-width: $bootstrapTabletBreakpoint) {
        grid-template-columns: repeat(auto-fill, 48%);
      }

      @media (max-width: $bootstrapMobileBreakpoint) {
        display: block;
      }

      li {
        @media (max-width: $bootstrapMobileBreakpoint) {
          margin-bottom: .5em;
          &:last-child {
            margin-bottom: 0;
          }
        }

        label {
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          -webkit-transform:translate3d(0,0,0);
          cursor: pointer;
          position: relative; //constrains the input position to keep current tab focus in view
          &:hover, &:focus-within {
            .card {
              background: #D7D7D7;
              color: #494949;
              overflow: hidden;
              @media (max-width: $bootstrapMobileBreakpoint) {
                position: relative;
                background-color: #494949;
                color: white;
                &::after {
                  z-index: 0;
                  transform-origin: center center;
                  -webkit-transform-origin: center center;
                  position: absolute;
                  left: var(--x-coord);
                  top: var(--y-coord);
                  content: var(--content);
                  height: 10px;
                  width: 10px;
                  max-height: 100%;
                  max-width: 100%;
                  background: #ba0c2f;
                  animation: color-change 1.2s linear;
                  border-radius: 50%;
                }
              }
            }
          }

          input {
            opacity: .001;
            position: absolute;
            top: 0;
            left: 0;
            //TODO does this need adjusted for mobile?
            scroll-margin-bottom: var(--contentPadding); //adds spacing under input fields to presserve visibility when autoscrolling
            &:checked + .card {
              background: #ba0c2f !important;
              transition-delay: 0.3s;
              color: white !important;
              animation: flip 0.6s ease-in-out;
              @media (max-width: $bootstrapMobileBreakpoint) {
                transition-delay: 1s;
                background: #BA0C2F !important;
                transition-duration: 0s;
              }
              span {
                position: relative;
                animation: flip-text 0.6s ease-in-out;
                z-index: 4;
                @media (max-width: $bootstrapMobileBreakpoint) {
                  animation: none;
                }
              }
            }

            &:focus-visible + .card {
              outline: 3px solid #ba0c2f; //highlight the tabbed elements
            }
          }

          .card {
            background: #494949;
            color: white;
            height: 100%;
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .2s;
            min-height: 8em;

            @media (max-width: $bootstrapMobileBreakpoint) {
              min-height: 0;
              text-align: left;
              display: block;
              -webkit-transform:translate3d(0,0,0);
              will-change: transform , opacity;
            }
          }
        }
      }
    }
  }
}
