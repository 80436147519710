@import "../variables";

.results-page {
	padding: 0 var(--contentPadding); //equalizes left indent to match question text

	h2 {
		font-size: 2rem;
	}

	h2 b{
		font-size: 1.5em;
	}

	form {
		margin: 1rem 0 3rem;

		p {
			font-size: 1.25rem;
		}

		.field {
			display: flex;
			gap:1em;
			margin: 1rem 0;

			@media (max-width:$bootstrapMobileBreakpoint){
				display: block;
			}

			label {
				display: block;
				font-size: .9rem;
				font-weight: 600;

				@media (max-width:$bootstrapMobileBreakpoint){
					margin-top: 1em;
				}

				span {
					color:#ba0c2f;
				}
				input {
					font-weight: normal;
					margin-top: .25rem;
				}
			}

			input {
				width: 100%;
				max-width: 300px;
				font-size: 1.1rem;
				padding: .35em .5em;
				margin-right: 1em;

				@media (max-width:$bootstrapMobileBreakpoint){
					font-size: 1.25rem;
					flex:1 1;
					max-width: none;
					padding: .5em .5em;
				}

				&::placeholder {
					font-style: italic;
					color: #BBB;
				}
			}
			.field{
				display: flex;
				flex-direction: column;
			}
		}

		small {
			margin-top: 2em;
			font-style: italic;
			display: block;
			@media (max-width:$bootstrapMobileBreakpoint){
				font-size: 1rem;
			}
		}

		button {
			@media (max-width:$bootstrapMobileBreakpoint){
				font-size: 1.25rem;
				margin-top: 1em;
			}
		}
	}
	.placeholder-video {
		max-width:800px;
		width:100%;
	}

	.results {
		.submitted {
			font-style: italic;
			margin:1em 0;
			font-size: 1.25rem;
			font-weight: 400;
		}
		h2 {
			font-weight: 300;
			font-size: 1.25rem;
			margin-bottom: 1em;
			output {

			}
			em {
				font-size: 2rem;
				font-weight: 600;
			}
		}
	}

	.icon-results {
		@media (max-width:$bootstrapMobileBreakpoint){
			display: flex;
			align-items: flex-end;
			margin:1em 0;
		}

		img {
			width:50px;
			display: none;
			@media (max-width:$bootstrapMobileBreakpoint){
				display: block;
				margin-right: 1em;
			}
		}
	}

	.icon-block {
		display: flex;
		align-items: flex-start;
		gap:2em;
		margin-bottom: 3rem;

		@media (max-width:$bootstrapMobileBreakpoint){
			display: block;
		}

		img {
			max-width:100px;
			@media (max-width:$bootstrapMobileBreakpoint){
				display: none;
			}
		}

		p {
			font-weight: 100;
			font-size: 1.25em;
			max-width:50ch;
			line-height: 1.6;
		}
	}

	.bio-box {
		margin-bottom: 4rem;
		border-top: 30px solid #BA0C2F;
		background: #494949;
		padding:2rem;
		display: flex;
		width:100%;
		max-width:500px;
		align-items:flex-start;
		gap:1rem;
		color:white;

		@media (max-width:$bootstrapMobileBreakpoint){
			display: block;
			max-width: none;
		}

		img {
			flex:1 1 25%;
			max-width:150px;

			@media (max-width:$bootstrapMobileBreakpoint){
				float:left;
				width:75px;
				margin:0 1em .5em 0;
			}
		}

		div {
			flex: 2 2 66%;
		}

		h3 {
			font-size: 1.5rem;
			@media (max-width:$bootstrapMobileBreakpoint){
				margin-bottom: .5em;
				font-size: 1.25em;
			}
		}

		p {
			font-size: .9rem;
			@media (max-width:$bootstrapMobileBreakpoint){
				font-size: 1.15rem;
			}
			+ p {
				margin-top: 1em;
			}
		}

		a {
			color:white;
			font-weight: 600;
		}
	}
	footer.center{
		text-align: left;
		padding-top: 1rem;
		border-top: 1px solid #494949;
	}
	.outro {
		font-size: 1.5rem;
		margin-bottom: 1em;
	}

}